<template>
  <div>
    <div class="add main-block">
      <div class="add__box main-block__head">
        <h1 class="add__box-title main-block__title">
          <span v-if="!showSearch">Заказы</span>
          <span v-else>Поиск</span>
        </h1>
        <a key="search" @click="closeSearch" class="main-block__btn default-btn">
          <span v-if="showSearch" >
            Свернуть поиск
          </span>
          <span v-else>
            Показать поиск
          </span>
        </a>
        <input type="text" v-model="searchText" @focusout="textout()" v-on:keydown.enter.prevent="textout()" placeholder="Поиск заказов" />
      </div>
      <div class="table">
        <b-table
          v-if="!showSearch"
          id="my-table"
          style="width: 100%;"
          class="table__box"
          :borderless="true"
          :items="points"
          :fields="fields"
          :per-page="100"
          :current-page="1"
          small
        >
          <template #cell(name)="data">
            <span>
              {{data.item.name}}
            </span>
          </template>
          <template #cell(client)="data">
            <span>
              {{data.item?.employee?.name}}
            </span>
          </template>
          <template #cell(sum)="data">
            <span>
              {{data.item.sum ?? 0}}
            </span>
          </template>
          <template #cell(place_count)="data">
            <span>
              {{data.item?.containers?.length}}
            </span>
          </template>
          <template #cell(date)="data">
            <span>
              {{data.item.date}}
            </span>
          </template>
          <template #cell(stock)="data">
            <span>
              {{data.item.stock}}
            </span>
          </template>
          <template #cell(actions)="data">
            <a @click="removePoint(data.index)">
              <b-icon icon="trash-fill" aria-hidden="true" variant="danger" />
            </a>
          </template>
        </b-table>
        <b-table
          v-else
            id="my-table"
            style="width: 100%;"
            class="table__box"
            :borderless="true"
            :items="filteredList"
            :fields="point_fields"
            :per-page="100"
            :current-page="1"
            small
          >
            <template #cell(name)="data">
              <span>
                {{data.item.name}}
              </span>
            </template>
            <template #cell(client)="data">
              <span>
                {{data.item?.employee?.name}}
              </span>
            </template>
            <template #cell(sum)="data">
              <span>
                {{data.item.sum ?? 0}}
              </span>
            </template>
            <template #cell(place_count)="data">
              <span>
                {{data.item?.containers?.length}}
              </span>
            </template>
            <template #cell(date)="data">
              <span>
                {{data.item.date}}
              </span>
            </template>
            <template #cell(stock)>
              <span>
                <!-- {{data.item.stock}} -->
                ТОО Aqniet Group Основной склад
              </span>
            </template>
            <template #cell(actions)="data">
              <a @click="addPoint(data.item)">
                <b-icon icon="plus" aria-hidden="true" variant="danger" />
              </a>
          </template>
        </b-table>
        <p v-if="!points.length && !showSearch">
          Вы еще не добавили ни одного заказа
        </p>
        <p v-if="!filteredList.length && showSearch">
          Подходящих вашему запросу не было найдено
        </p>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      points: [],
      searchText: null,
      searchTextOld: null,
      showSearch: false,
      fields: [
        {
          key: "name",
          label: "Документ отгрузки",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "client",
          label: "Клиент",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "sum",
          label: "Сумма",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "place_count",
          label: "Кол-во мест",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "date",
          label: "Дата",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "stock",
          label: "Склад аптеки",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "actions",
          label: "Действия",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
      ],
      point_fields: [
        {
          key: "name",
          label: "Документ отгрузки",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "client",
          label: "Клиент",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "sum",
          label: "Сумма",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "place_count",
          label: "Кол-во мест",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "date",
          label: "Дата",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "stock",
          label: "Склад аптеки",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "actions",
          label: "Действия",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
      ],
    };
  },
  computed: {
    ...mapState(["searchPoints"]),
    filteredList(){
      return this.searchPoints.filter((point) => !this.points.includes(point));
    }
  },
  methods: {
    addPoint(x) {
      this.points.push(x);

      this.onChange('points', this.points)
    },
    removePoint(x) {
      this.points.splice(x, 1);
    },
    async onChange(key, param) {
      this.$emit("onInputHandler", {
        key,
        param,
      });
    },
    textout() {
      if (this.searchText) {
        this.showSearch = true;
        if (this.searchTextOld != this.searchText) {
          this.searchTextOld = this.searchText;
          this.$store.dispatch("getSearchPoints", {search: this.searchText}); 
        }
      } else {
        this.showSearch = false;
      }
    },
    closeSearch() {
      this.showSearch = !this.showSearch;
    }
  },
};
</script>

<style scoped>
.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
.city-add__map {
  margin-top: 24px;
  height: 300px;
  width: 100%;
}
.city-add__search-list {
  width: 50%;
  max-height: 250px;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(16, 51, 115, 0.08);
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2;
}
.city-add__search-item {
  padding: 10px 5px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
.city-add__search-item:not(:last-child) {
  border-bottom: 1px solid #b4b4b4;
}
.city-add__search-item:hover {
  background-color: rgba(203, 206, 225, 0.71);
}
.table {
  max-height: 80vh;
  overflow: auto;
}
a.default-btn {
  text-decoration: none;
  padding: 6px 8px;
}
.table__box {
  white-space: nowrap;
}
</style>
